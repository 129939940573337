.partnersContainer {
    position: relative;
    margin-bottom: -48px;
}

.partnersContainer h1 {
    margin-top: 32px;
    text-align: center;
}

.partnersLogo div {
    text-align: center;
}

.partnersLogo img {
    width: 160px;
    margin: 0 40px 32px 40px;
}

#brasilJrMark {
    width: 220px;
}

#totvsMark {
    width: 320px;
    margin-right: 0;
}

.partnersLogo div:last-child img {
    width: 440px;
}

#americanasMark {
    margin-right: 0;
}

/* Responsividade - Parceiros */

/** Mobile **/
@media screen and (min-width: 320px) and (max-width: 480px) {

    .partnersContainer {
        margin-bottom: -204px;
    }

    .partnersLogo{
        padding-left: 15px;
        padding-right: 15px;
    }

    .partnersLogo img {
        width: 56px;
        margin-right: 32px;
    }

    #brasilJrMark {
        width: 72px;
    }

    #totvsMark {
        width: 92px;
    }
    
    .partnersLogo div:last-child img {
        width: 180px;
    }

}

/** Tablets **/
@media screen and (min-width: 481px) and (max-width: 768px) {

    .partnersContainer {
        margin-bottom: -140px;
    }

    .partnersLogo img {
        width: 72px;
        margin: 0 24px;
    }

    #brasilJrMark {
        width: 96px;
    }


    #totvsMark {
        width: 140px;
    }
    
    .partnersLogo div:last-child img {
        width: 240px;
    }
    
}

/** Laptops **/
@media screen and (min-width: 769px) and (max-width: 1060px) {

    .partnersContainer {
        margin-bottom: -108px;
    }
    
    .partnersLogo img {
        width: 140px;
        margin: 0 32px;
    }

    #brasilJrMark {
        width: 160px;
    }

    #totvsMark {
        width: 196px;
    }

    .partnersLogo div:last-child img {
        width: 320px;
    }

}

@media screen and (min-width: 1061px) and (max-width: 1366px) {

    .partnersContainer {
        margin-bottom: -108px;
    }
    
    .partnersLogo img {
        width: 124px;
        margin-right: 32px;
    }

}