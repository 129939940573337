.hdrContainer {
    background-color: #610C7D;
    height: 720px;
    z-index: -2;
    display: flex;
}

.hdrTextContainer {
    margin: 0 auto;
    color: white;
    text-align: center;
}

.hdrTextContainer h1 {
    font-family: "Scada";
    font-size: 56px; 
    font-weight: bold;
    padding-top: 156px;
}

.hdrTextContainer p {
    margin-top: 32px;
    font-family: "Saira";
    font-size: 24px;
}

.hdrSocialMediaContainer {
    margin: 7.5% 5% 0 0;
    text-align: center;   
}

.hdrSocialMediaContainer p {
    font-family: "Saira"; 
    font-size: 26px;
    font-weight: bold;
    writing-mode:vertical-rl;
    color: white;
    margin-bottom: 90%;
}

.hdrSocialMediaContainer img:hover {
    transform: scale(1.3);
    transition: transform 0.5s;
}

.hdrBorder {
    width: 100%; 
    position: relative;
    margin-top: -25.6%;
    z-index: 0;
    -webkit-user-drag: none;  
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}  

#serviceInfoHeader {
    display: none;
}

#serviceInfoHeaderContainer {
    justify-content: center;
}

#serviceInfoHeaderP {
    text-align: center;
}

/* Responsividade */

/** Mobile **/
@media screen and (min-width: 320px) and (max-width: 480px) {

    .hdrContainer {
        height: 460px;
    }

    #serviceInfoHeaderContainer{
        height: 340px;
    }

    .hdrTextContainer {
        margin: 0 5%;
    }

    .hdrTextContainer h1 {
        text-align: left;
        padding-top: 96px;
        font-size: 28px;
    }

    .hdrTextContainer p {
        text-align: left;
        font-size: 16px;
    }

    .hdrSocialMediaContainer {
        display: none;
    }
}

/** Tablets **/
@media screen and (min-width: 481px) and (max-width: 768px) {

    .hdrContainer {
        height: 600px;
    }

    #serviceInfoHeaderContainer{
        height: 480px;
    }

    .hdrTextContainer h1 {
        text-align: left;
        padding-top: 108px;
        font-size: 44px;
    }

    .hdrTextContainer p {
        text-align: left;
        font-size: 20px;
    }

    .hdrSocialMediaContainer {
        display: none;
    }

}

/** Laptops: Large Screen **/
@media screen and (min-width:769px) and (max-width: 1023px) {

    .hdrContainer {
        height: 700px;
    }

    #serviceInfoHeaderContainer{
        height: 580px;
    }

    .hdrTextContainer {
        margin: 0 5%;
    }

    .hdrTextContainer h1 {
        font-size: 48px;
    }

    .hdrTextContainer p {
        font-size: 20px;
    }

    .hdrSocialMediaContainer {
        padding-top: 10%;
    }
}

/** Laptops: Large Screen **/
@media screen and (min-width: 1024px) and (max-width: 1366px) {

    .hdrContainer {
        height: 720px;
    }

    #serviceInfoHeaderContainer{
        height: 600px;
    }

    .hdrTextContainer {
        margin: 0 5%;
    }

    .hdrSocialMediaContainer {
        padding-top: 5%;
    }

}
