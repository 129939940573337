.aboutContainer{
    position: relative;
    width: 100%;
    display: flex; 
    background: #f7f7f7;
    padding-left: 144px;
    text-align: left;
    align-items: center;
}

.aboutTextContainer {
    margin-right: 96px;
    margin-bottom: 100px;
}

.aboutTextContainer h2 {
    text-align: left;
    margin-top: 40px;
    padding-left: 0px;
}

.aboutTextContainer h1 {
    text-align: left;
    padding-left: 0px;
}

.aboutTextContainer p {
    color: #9C9C9C;
    font-size: 24px;
    font-family: "Saira";
}

#aboutDesktopImg{
    display: flex;
    justify-content: center;
}

#aboutDesktopImg img {
    width: 540px;
    margin-right: 96px;
}

#aboutMobileImg{
    display: none;
}

/* Responsividade - Sobre Nós */

/** Mobile **/

@media screen and (min-width: 320px) and (max-width: 1023px) {

    .aboutTextContainer h1, .aboutTextContainer h2 {
        padding-left: 0px;
        text-align: center;
    }

    .aboutImg {
        margin-right: 0px;
    }

    .aboutContainer{
       text-align: center;
       padding: 0px;
       justify-content: center;
    }

    .aboutTextContainer a {
        font-size: 20px;
    }

    .aboutTextContainer{
        padding: 10%;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .aboutTextContainer p {
        font-size: 20px;
    }

    #aboutMobileImg{
        display: flex;
        justify-content: center;
    }
    
    #aboutMobileImg img{
        width: 36%;
    }
    
    #aboutDesktopImg{
        display: none;
    }
}

/** Laptops: Large Screen **/

@media screen and (min-width: 1024px) and (max-width: 1366px) {

    .aboutTextContainer h1, .aboutTextContainer h2 {
        padding-left: 0px;
    }
    
    .aboutTextContainer p {
        font-size: 22px;
    }

    #aboutDesktopImg img {
        width: 200px;
    }
    
}