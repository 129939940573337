.footerContainer {
    padding: 0 144px 8px 144px;
    background-color: #610C7D;
    color: white;
}

.footerInfo {
    display: flex;
    margin-top: 250px;
    padding-top: 96px;
    width: 100%;
    font-family: "Saira";
    font-size: 20px;
    align-items: center;
    justify-content: center;
}
.footerInfo a:hover {
    color: white;
    opacity: 0.8;
}

.footerLogo {
    width: 352px;
    margin-right: 296px;
    position: relative;
    top: -48px;
}

.footerInfo h1 {
    font-family: "Scada";
    font-size: 32px;
    margin-bottom: 24px;
    font-weight: bold;
}

.footerBorder {
    width: 100%;
    position: relative;
    margin-bottom: -604px;
    -webkit-user-drag: none;  
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.footerPages {
    margin-right: 144px;
    z-index: 1;
    display: none;
}

.footerPages ul, li {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 24px;
}

.footerPages a {
    color: white;
    text-decoration: none;
}

.footerContact * {
    margin-bottom: 10px;
}

.footerContact div:not(:last-child) {
    display: flex;
    align-items: center;
}

.footerContact a {
    color: white;
    text-decoration: none;
}

.footerContact img {
    width: 28px;
    margin-right: 16px;
}

.footerContact img:hover {
    transform: scale(1.3);
    transition: transform 0.5s;
}

.footerContact a:hover{
    opacity: 1;
}

#copyright {
    font-size: 18px;
    text-align: center;
}

/* Responsividade: Mobile */

@media screen and (min-width: 320px) and (max-width: 768px) {

    .footerContainer {
        padding: 0 48px 0 48px;
    }

    .footerInfo {
        display: block;
        text-align: center;
    }

    .footerLogo {
        width: 124px;
        margin-right: 0px;
    }

    .footerPages {
        display: none;
    }

    .footerContact p, .footerContact a {
        font-size: 16px;
        text-align: left;
    }

    .footerContact div:not(:last-child) {
        justify-content: center;
    }

    .footerContact div:last-child { /*Redes Sociais do Footer */
        text-align: center;
        margin: 10px 16px 10px 0;
    }

    .footerContact div:last-child img { /*Redes Sociais do Footer */
        width: 40px;
        margin: 16px;
    }

    .footerBorder {
        margin-bottom: -560px;
    }

    #copyright {
        font-size: 16px;
        padding-bottom: 8px;
    }

}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .footerBorder {
        margin-bottom: -550px;
    }
}

/* Responsividade: Laptops */

@media screen and (min-width: 769px) and (max-width: 1060px) {

    .footerLogo {
        width: 250px;
        margin-right: 64px;
    }

    .footerPages {
        margin-right: 72px;
    }

    .footerInfo h1 {
        font-size: 28px;
    }

    .footerInfo p, .infoInfo a {
        font-size: 18px;
    }

    .footerBorder {
        margin-bottom: -576px;
    }

}

@media screen and (min-width: 1061px) and (max-width: 1366px) {

    .footerLogo {
        width: 250px;
        margin-right: 64px;
    }

    .footerPages {
        margin-right: 72px;
    }

    .footerInfo h1 {
        font-size: 28px;
    }

    .footerInfo p, .infoInfo a {
        font-size: 18px;
    }

    .footerBorder {
        margin-bottom: -576px;
    }
}

/* Responsividade: Ultrawide */

@media screen and (min-width: 1920px) and (max-width: 2100px) {

    .footerBorder {
        margin-bottom: -610px;
    }
}

@media screen and (min-width: 2101px) and (max-width: 2224px) {

    .footerBorder {
        margin-bottom: -628px;
    }
}

@media screen and (min-width: 2225px) and (max-width: 2300px) {

    .footerBorder {
        margin-bottom: -640px;
    }
}

@media screen and (min-width: 2301px) and (max-width: 2400px) {

    .footerBorder {
        margin-bottom: -660px;
    }
}

@media screen and (min-width: 2401px) and (max-width: 2560px) {

    .footerBorder {
        margin-bottom: -685px;
    }
}

