.teamListContainer {
    position: relative;
    margin-bottom: -280px;
    padding: 32px 10% 10% 10%;
    background-repeat: no-repeat;
    background-color: #F6F6F6;
    text-align: center;
}

.teamBg {
    position: absolute;
    z-index: -1;
}

.teamListContainer h1 {
    margin-bottom: 48px;
}

.listContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.listContainer::-webkit-scrollbar {
    display: none;
}

/* Desktop */

.memberCard {
    height: 200px;
    width: 200px;
    background-size: cover;
    margin: 0 20px 40px 20px;
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    border-radius: 15px;
    transition: transform 0.5s;
}

.memberCard:hover {
    box-shadow: inset 0 0 0 1000px rgba(135, 0, 169, 0.7);
    transform: scale(1.1);
}

.memberInfo {
    text-align: center;
    margin: auto 0;
    color: white;
    display: none;
    font-family: Scada;
}

.memberInfo h3 {
    font-size: 18px;
    font-weight: bold;
}

.memberInfo p {
    font-family: Saira;
    font-weight: lighter;
    font-size: 14px;
}

.memberSocialMedia {
    display: none;
    text-align: center;
    margin-top: -40px;
    margin-bottom: 8px;
}

.memberSocialMedia img {
    margin: 0 10px;
}

/* Correção de diferença de tamanho */
.linkedinIcon {
    width: 24px;
}

.emailIcon{
    width: 24px;
    color: #610C7D
}

.githubIcon {
    width: 22px;
}
.instagramIcon {
    width: 23px;
}
/* --------------------------------- */

.memberCard:hover .memberInfo {
    display: block;
}

.memberCard:hover .memberSocialMedia {
    display: block;
}

#subscription {
    display: flex;
    position: relative;
    justify-content: end;
    position: initial;
    margin-top: 72px;
    padding-bottom: 240px;
    margin-bottom: -18%;
    clear: both;
}

/* Mobile */ 

.memberList {
    display: none;
    text-align: left;
    margin-bottom: 8px;
}

.memberListAvatar img {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    margin-right: 16px;
}

.memberList h3 {
    font-family: "Scada";
    font-size: 20px;
    font-weight: bold;
    color: #610C7D;
}

.memberList p {
    font-family: "Saira";
    font-weight: lighter;
    font-size: 16px
}

.memberListInfoContainer {
    flex-grow: 100;
}

.memberListInfo {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.memberListSocialMedia{
    display: flex;
    margin-right: 8px;
}


.memberListSocialMedia img {
    margin: 0px 5px 10px 5px;
}

/* Responsividade */

/** Mobile **/
@media screen and (min-width: 320px) and (max-width: 480px) {

    .memberCard {
        display: none;
    }

    .memberList {
        display: flex;
    }

    .teamListContainer {
        padding: 0 0 280px 7%;
        background-size: 0;
    }

    .teamListContainer h1 {
        margin-bottom: 16px;
    }

    .listContainer {
        display: block;
        height: 516px;
        overflow: auto;
    }

    #subscription {
        margin-top: 32px;
        padding-bottom: 48px;
        padding-right: 5%;
        margin-bottom: -60%;
    }

    #subscription a {
        font-size: 16px;
    }

}

/** Tablets **/
@media screen and (min-width: 481px) and (max-width: 768px) {

    .memberCard {
        display: none;
    }

    .memberList {
        display: flex;
    }

    .teamListContainer {
        padding-left: 7%;
        padding-right: 0;
        background-size: 0;
        padding-bottom: 320px;
    }

    .memberListAvatar img {
        width: 72px;
        height: 72px;
        border-radius: 72px;
    }

    .memberList h3 {
        font-size: 24px;
    }

    .memberList p {
        font-size: 18px;
    }

    #subscription {
        margin-top: 32px;
        padding-bottom: 72px;
        padding-right: 5%;
        margin-bottom: -39%;
    }

    #subscription a {
        font-size: 20px;
    }

    .listContainer {
        display: block;
        height: 516px;
        overflow: auto;
    }

}

/** Laptops: Large Screen **/
@media screen and (min-width: 769px) and (max-width: 1060px) {

    .teamListContainer {
        margin-bottom: -280px;
        padding-bottom: 380px;
    }

    .memberCard {
        width: 172px;
        height: 172px;
    }

    #subscription {
        display: flex;
        justify-content: end;
        position: initial;
        margin-top: 56px;
        padding-bottom: 200px;
        padding-right: 2%;
        margin-bottom: -50%;
        clear: both;
    }

    #subscription a {
        font-size: 24px;
    }
    
}


@media screen and (min-width: 1061px) and (max-width: 1366px) {

    .teamListContainer {
        margin-bottom: -280px;
        padding-bottom: 440px;
    }

    .memberCard {
        width: 172px;
        height: 172px;
    }

    #subscription {
        display: flex;
        justify-content: end;
        position: initial;
        margin-top: 32px;
        padding-bottom: 200px;
        padding-right: 2%;
        margin-bottom: -42%;
        clear: both;
    }
}