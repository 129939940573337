.statusContainer {
    margin: 5% 0;
    position: relative;
}

.statusContainer h1 {
    text-align: center;
    color: #8700A9;
    font-family: Scada;
    font-weight: bold;
    margin-bottom: -24px;
}

.statusContainer h1 span {
    background:#fff; 
    padding:0 20px;
}

.statusNumbers {
    display: flex;
    align-content: center;
    border: 6px solid #8700A9;
    border-radius: 30px;
    padding: 56px 0;
    margin: 0 15%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
}

.statusNumbers div {
    margin: 0 16px;
    text-align: center;
}

.statusNumbers span {
    font-family: Scada;
    font-weight: bold;
    font-size: 40px;
    color: #8700A9;
    animation: slide 5s;
}

.statusNumbers p{
    font-family: Saira;
    font-weight: lighter;
    font-size: 24px;
}

.statusRow{
    display: flex;
    width: 50%;
    justify-content: space-around;
}

/* Correções de alinhamento */

.productsContainer img {
    margin-bottom: 20px;
}

.evolutionContainer img {
    margin-bottom: 20px;
}

@media (max-width: 1025px){
    .statusRow{
        display: block;
    }
}

@media (max-width: 825px){
    #evolutionContainer{
        margin-top: 50px;
    }
}

@media (max-width: 580px){
    .statusNumbers {
        margin: 0 4%;
    }
    .statusNumbers img{
        width: 70px;
    }
    .statusNumbers span {
        font-size: 30px;
    }
    .statusNumbers p{
        font-size:20px;
    }
}
