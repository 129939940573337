.aboutUsContainer {
    display: flex;
    text-align: left;
    color: #9C9C9C;
    margin-top: -15%;
    padding: 5% 5% 5% 0%;
    font-size: 24px;
    align-items: center;
}

.aboutUsContainer p{
    padding-left: 5%;
}


.aboutContent{  
    display: flex;
    flex-direction: column;
    padding: 2%;
}


.aboutUsTitleImg{
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
}

.aboutUsTitleImg img{
    display: none;
}


/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */

@media (max-width: 768px) {
    .aboutUsTitleImg img{
        display: flex;  
        width: 80px;
        margin-right: 2%; 
    }
    .aboutUsImg{
       display: none;
    }
    .aboutContent h1{
        margin-top: -90px;
        margin-bottom: 5%;
    }
    .aboutContent p{
        margin-top: -18px;
        font-size: 20px;  
    }    
}

@media (max-width: 568px) {
    .aboutContent h1{
        font-size: 30px;  
        margin-top: -65px;
    }    
    .aboutContent h2{
        font-size: 25px;  
    }
    .aboutUsTitleImg img{
        width: 60px;
    }
}

@media (max-width: 475px) {
    .aboutContent h1{
        font-size: 26px;
        width: 95%;  
    }    
    .aboutContent h2{
        margin-bottom: 70px;
        font-size: 25px;  
    }
    .aboutUsTitleImg img{
        margin-right: -2%;
        width: 40px;
    }
}