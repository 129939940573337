@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.solutionsContainer {
    display: flex;
    background-image: url("../../../assets/imgs/solucoesBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: -20%;
    margin-bottom: 48px;
    padding-top: 244px;
    padding-left: 72px;
    padding-right: 72px;
}

.solutionsContainer .flex {
    display: flex;
}

.solutionsContainerButtons{
    display: flex;
    justify-content : space-evenly;
    gap: 1.5rem;
}

#solutionsButtonClicked {
    width: 267.56px;
    height: 88px;
    font-family: "Saira";
    font-size: 25px;
    color: white;
    border-radius: 50px;
    border: white 3px solid;
    margin: 10px 10px;
    
}

.solutionsChangeContent{
    display: flex;
    margin-top: 40px;
}

.solutionsChangeContent img{
    width: 500px;
    height: 500px;
    margin-left: 3%;
}

.solutionsContainerText{
    text-align: right;
    margin-right: 3%;
    display: flex;
    flex-direction: column;
    align-items: end;
}
.solutionsContainerText.services {
    text-align: justify;
    align-items: center;
}

.solutionsTitle{
    width: fit-content;
    border-bottom: #610C7D 8px solid;
    margin-bottom: 20px;
}

.solutionsTitle h1{
    font-family: "Scada";
    font-weight: bolder;
    color: #610C7D;
}

.solutionsContainerText p{
    width: 80%;
    color: #707070;
    font-family: "Scada";
    font-size: 25px;
    text-align: right;
    margin-bottom: 20px;
}
.solutionsContainerText.services p {
    text-align: justify;
}

.solutionsContainerMobile{
    display: none;
}

.flexColumn {
    flex-direction: column;
}

/* Responsividade */

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */


/** Mobile e Tablets **/
@media screen and (min-width: 320px) and (max-width: 768px) {

    .solutionsContainer{
        margin-top: 30px;
        padding: 15px;
        background: none;
    }  

    .solutionsContainer h2{
        margin-top: -40px;
    }

    .solutionsContainerButtons{
        display: none;
    }
    .solutionsChangeContent{
        display: none;
    }
    .solutionsContainerMobile{
        display: flex;
        flex-direction: column;
    }
    .solutionsCardMobile {
        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.4);
        border-radius: 30px;
        text-align: left;
        margin-bottom: 40px;
        padding: 20px;
        font-family: "Scada";
        color: #707070;
        margin-right: 16px;
        margin-left: 16px;
    }

    .solutionsCardMobile h1 {
        font-weight: bolder;
    }

    .solutionsCardMobile img{
        width: 12% ;
    }

    .solutionsCardMobile img:last-of-type{
        width: 50%;
        position: absolute;
        margin-left: 20%;
        z-index: -1;
        opacity: 0.2;
    }
    .solutionsTitleMobile {
        width: fit-content;
        color: #610C7D;
        border-bottom: #610C7D 7px solid;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .solutionsCardMobile p{
        font-size: 18px;
    }
}

/** Mobile: Correção de tamanho de imagens **/
@media screen and (min-width: 620px) and (max-width: 768px) {
    .solutionsCardMobile img:last-of-type{
        width: 40%;
        margin-left: 35%;
    }
}

/** Laptops **/
@media screen and (min-width: 769px) and (max-width: 1600px) {
    .solutionsContainer{
        padding-left: 0px;
        padding-right: 0px;
    }

    .solutionsContainer h2 {
        margin-top: -80px;
    }

    #solutionsButtonClicked {
        font-size: 22px;
    }

    .solutionsChangeContent img{
        width: 400px;
        height: 400px;
    }
}