.coreValuesContainer {
    position: relative;
    background-color: #F6F6F6;
    background-image: url("../../../assets/svg/img.core-values-bg.svg");
    background-position: right;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 32px;
}

.valueCardsContainer {
    display: flex;
    margin-top: 32px;
    padding-top: 32px;
    padding-bottom: 64px;
    justify-content: center;
}

.valueCardsContainerMobile {
    display: none;
}

.valueCardsCarousel {
    display: none;
    position: relative;
}

.valueCardsSelection {
    display: flex;
    justify-content: center;
}

.valueCardsSelection p {
    font-family: 'Scada';
    font-weight: bolder;
    font-size: 12px;
    color: #707070;
    margin-top: 8px;
}

.valueCardsSelection button {
    display: flex;
    height: 64px;
    width: 64px;
    border: 2px solid #8700A9;
    border-radius: 64px;
    background-color: white;
    margin: 0 8px;
}

.valueCardsSelection img {
    height: 32px;
    width: 32px;
    margin: auto auto;
}

/* Responsividade */

/* Mobile & Tablets */

@media screen and (min-width: 320px) and (max-width: 380px) {

    .valueCardsContainerMobile {
        display: block;
    }
    
    .valueCardsSelection button {
        margin: 0 8px;
        height: 40px;
        width: 40px;
    }

    .valueCardsSelection img {
        height: 20px;
        width: 20px;
    }

    .valueCardsContainer {
        display: none;
    }

    .valueCardsSelection p {
        font-size: 10px;
        font-weight: lighter;
    }
}


@media screen and (min-width: 381px) and (max-width: 480px) {

    .coreValuesContainer {
        padding-bottom: 16px;
        background-position: top left 50px;
        background-size: 110%;
    }

    .valueCardsSelection button {
        margin: 0 12px;
        height: 50px;
        width: 50px;
    }

    .valueCardsSelection p {
        font-weight: lighter;
    }

    .valueCardsContainer {
        display: none;
    }

    .valueCardsContainerMobile {
        display: block;
    }

}

/* Tablets */
@media screen and (min-width: 481px) and (max-width: 768px) {

    .valueCardsContainer {
        display: none;
    }

    .valueCardsCarousel {
        display: block;
    }

}

/* Laptops */
@media screen and (min-width: 769px) and (max-width: 1366px) {

    .valueCardsContainer {
        display: none;
    }

    .valueCardsCarousel {
        display: block;
    }

}