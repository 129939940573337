@import url('https://fonts.googleapis.com/css?family=Saira');
@import url('https://fonts.googleapis.com/css?family=Scada');

* {
    padding: 0; /*Padding: espaçamento interno*/
    margin: 0; /*Margem: espaçamento externo*/
    z-index: 1;
}

.App {
    overflow-x: hidden;
}

.title {
    font-family:"Scada";
    font-size: 48px;
    font-weight: bold;
    color: #610C7D;
    padding: 0% 5% 24px 5%;
}

.subtitle {
    color: #9C9C9C;
    font-family:"Saira";
    font-size: 32px;
    font-weight: lighter;
    padding: 0 5% 0 5%;
}

@media screen and (min-width: 320px) and (max-width: 480px) {

    .title {
        font-size: 28px;
    }

    .subtitle {
        font-size: 20px;
    }

}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .title {
        font-size: 36px;
    }

    .subtitle {
        font-size: 24px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .title {
        font-size: 40px;
    }

    .subtitle {
        font-size: 26px;
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {

    .title {
        font-size: 28px;
    }

    .subtitle {
        font-size: 20px;
    }

}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .title {
        font-size: 36px;
    }

    .subtitle {
        font-size: 24px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .title {
        font-size: 40px;
    }

    .subtitle {
        font-size: 26px;
    }
}

/* Estilização do container de particulas */
.tsparticles {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 0;
}

/* Estilização do Carrosel */
.carousel-indicators [data-bs-target] {
    width: 16px !important;
    height: 16px !important;
    background-color: #610C7D !important;
    margin-right: 32px !important;
    border-radius: 100% !important;
}

.carousel-control-next {
    display: none!important;
}

/* Botoes */

.buttonPurple {
    color: #8700A9;
    text-decoration: none;
    border: 2px solid #8700A9;
    border-radius: 30px;
    padding: 4px 40px 4px 40px;
    z-index: 2;
    font-family: "Saira";
    font-size: 24px;
}

.buttonPurple:hover {
    color: white;
    background-color: #8700A9;
}

.buttonPurple2 {
    color: white;
    background-color: #8700A9;
    text-decoration: none;
    border: 2px solid #8700A9;
    border-radius: 30px;
    padding: 8px 40px 8px 40px;
    z-index: 2;
    font-family: "Saira";
    font-size: 24px;
}

.buttonPurple2:hover {
    color: white;
    background-color: #6A0085;
}

.buttonPurple2 img {
    width: 20px;
    margin-left: 8px;
}

.buttonWhite {
    color: white;
    text-decoration: none;
    border: 2px solid white;
    border-radius: 30px;
    padding: 8px 40px 8px 40px;
    z-index: 2;
    background: none;
    font-family: "Saira";
    font-size: 24px;
}

.buttonWhite img {
    width: 20px;
    margin-left: 4px;
}

.buttonWhite:hover {
    color: #8700A9;
    fill: #8700A9;
    background-color: white;
}

@media screen and (min-width: 320px) and (max-width: 480px) {

    .title {
        font-size: 28px;
    }

    .subtitle {
        font-size: 20px;
    }

    .buttonWhite {
        border: 1px solid white;
        font-size: 12px;
        padding: 8px 32px 8px 32px;
    }
}

/* Cards */

.cardMobile {
    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.4);
    height: 360px;
    background-color: white;
    border-radius: 30px;
    text-align: left;
    margin: 32px 16px 40px 16px;
    padding: 20px;
    font-family: "Scada";
    color: #707070;
}

.cardMobile h1 {
    width: fit-content;
    color: #610C7D;
    border-bottom: #610C7D 7px solid;
    margin: 20px 0;
    font-weight: bolder;
}

.cardMobile p {
    font-size: 18px;
}

.cardMobile img {
    width: 56px;
}

.cardMobile img:last-of-type{
    width: 50%;
    position: absolute;
    margin-left: 25%;
    z-index: 0;
    opacity: 0.2;
}

@media screen and (min-width: 320px) and (max-width: 380px) {

    .cardMobile {
        height: 420px;
    }
}

/* Text Input */

/*Modifique a partir daqui */



input[type=number] {
    -moz-appearance:textfield;
}