.testimonialCard {
    margin: 5px 10% 80px 10%;
    padding: 24px 24px;
    align-items: center;
    display: flex;
    border-radius: 50px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}

.testimonialAvatar img {
    width: 296px;
    border-radius: 296px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}

.testimonialCard h1 {
    font-family: "Scada";
    font-size: 48px;
    font-weight: bold; 
    color: #424242
}

.testimonialCard p:first-of-type {
    font-family: "Scada";
    font-size: 24px;
    color: #707070;
}

.testimonialCard p {
    font-family: "Saira";
    font-size: 28px;
    color: #707070;
    font-weight: lighter;
    margin-top: 16px;
}

/* Responsividade */

@media screen and (min-width: 320px) and (max-width: 480px) {

    .testimonialCard {
        margin-left: 5%;
        margin-right: 5%;
    }
    
    .testimonialAvatar {
        align-self: flex-start;
    }

    .testimonialAvatar img {
        position: absolute;
        margin-left: 16px;
        width: 64px;
    }

    .testimonialCard h1 {
        font-size: 24px;
        margin-left: 72px;
    }

    .testimonialCard p:first-of-type {
        font-size: 16px;
        margin-left: 72px;
    }

    .testimonialCard p {
       font-size: 16px;
       margin-top: 0%;
    }

    .quotationMark {
        display: none;
    }


}

@media screen and (min-width: 481px) and (max-width: 768px) {

    .testimonialAvatar {
        align-self: flex-start;
    }

    .testimonialAvatar img {
        position: absolute;
        margin-left: 16px;
        width: 80px;
    }

    .testimonialCard h1 {
        font-size: 40px;
        margin-left: 96px;
    }

    .testimonialCard p:first-of-type {
        font-size: 24px;
        margin-left: 96px;
        margin-top: 8px;
    }

    .testimonialCard p {
        font-size: 22px;
        margin-top: 0%;
     }

    .quotationMark {
        display: none;
    }

}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .testimonialAvatar img {
        width: 172px;
    }

    .testimonialCard p {
        font-size: 22px;
        margin-top: 0%;
    }

     .quotationMark {
        width: 48px;
    }
}