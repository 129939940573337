.contactContainer {
    display: flex;
    width: 100%;
    background-image: linear-gradient(#8700A9, #980089);
	justify-content: space-evenly;
    align-items: center;
    padding-top: 72px;
    padding-bottom: 72px;
}

#contactImg {
    width: 640px;
    margin-top: 30;
}

.contactForm{
    display: flex;
    flex-direction: column;
	align-items: center;
    color: white;
}

.contactForm h1 {
    font-size: 50px;
    font-family: "Saira";
    font-weight: bold;
}

.contactForm p{
    font-family: "Saira";
    font-weight: lighter;
    font-size: 24px;
    text-align: center;
}

.contactForm input{
    border-radius: 18px;
	height: 50px;
	width: 90%;
	outline: none;
    border: #610C7D 3px solid;
	padding-left: 15px;
    padding-right: 15px;
	margin-top: 10px;
	font-family: "Saira";
}

.contactForm input::placeholder {
    color: #BABABA;
    font-size: 20px;
}

.contactForm textarea {
    border-radius: 18px;
    padding-top: 16px;
	height: 120px;
	width: 90%;
	outline: none;
    border: #610C7D 3px solid;
	padding-left: 15px;
	margin-top: 10px;
	font-family: "Saira";
    resize: none;
}

.contactForm textarea::placeholder {
    color: #BABABA;
    font-size: 20px;
}


.contactForm input::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

#contactInputMessage {
	    height: 90px;
}

/* Responsividade */

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */

@media (max-width: 768px) {
    #contactImg{
        display: none;
    }
    .contactForm p{
        padding: 1% 5%;
    }
    .buttonWhite{
        font-size: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .contactForm{
        margin-right: 2%;
    }
    #contactImg{
        width: 50%;
    }
}