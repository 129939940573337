@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.headerContainer {
    background-color: #610C7D;
    z-index: -2;
    margin-top: 80px;
}

.headerTextContainer {
    display: flex; 
    align-items: center; 
    margin-left: 5%;
}

.headerTextContainer h1 {
    color: white;
    font-family: "Scada";
    font-size: 72px; 
    font-weight: bold;
}

.headerTextContainer p {
    margin-top: 20px;
    color: white;
    font-family: "Saira";
    font-size: 28px;
}

.headerTextContainer h1, .headerTextContainer p, .headerTextContainer a {
    animation: fadeInAnimation ease-in 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#headerImg {
    height: 800px;
    position: relative;
}

.headerSocialMediaContainer {
    margin-right: 5%;
}

.headerSocialMediaContainer p {
    font-family: "Saira"; 
    font-size: 26px;
    font-weight: bold;
    writing-mode:vertical-rl;
    color: white;
}

.headerSocialMediaContainer img:hover {
    transform: scale(1.3);
    transition: transform 0.5s;
}

.headerBorder {
    width: 100%; 
    position: relative;
    margin-top: -25.5%;
    z-index: 0;
    -webkit-user-drag: none;  
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/* Responsividade - Header */

/** Mobile **/
@media screen and (min-width: 320px) and (max-width: 480px) {

    .headerTextContainer {
        padding-top: 5%;
    }

    .headerTextContainer h1 {
        font-size: 28px;
    }

    .headerTextContainer p {
        font-size: 16px;
    }

    .headerButton {
        margin-bottom: 10%;
    }

    #headerImg {
        display: none;
    }

    .headerSocialMediaContainer {
        display: none;
    }

    .headerBorder {
        margin-top: -26%;
    }
}

/** Tablets **/
@media screen and (min-width: 481px) and (max-width: 1024px) {

    .headerContainer {
        padding: 5%;
    }
    
    .headerContainer h1 {
        font-size: 48px;
    }

    .headerContainer p {
        font-size: 24px;
        margin-right: 5%;
    }

    #headerImg {
        display: none;
    }

    #headerImg {
        display: none;
    }

    .headerSocialMediaContainer {
        margin-right: 2%;
    }

    .headerBorder {
        margin-top: -25.6%;
    }

}

/** Laptops: Large Screen **/
@media screen and (min-width: 1025px) and (max-width: 1600px) {

    .headerContainer {
        padding: 3%;
    }

    .headerContainer h1 {
        font-size: 64px;
    }

    .headerContainer p {
        font-size: 24px;
        margin-right: 5%;
    }

    #headerImg {
        height: 520px;
    }

    .headerSocialMediaContainer {
        margin-right: 2%;
    }

    .headerBorder {
        margin-top: -25.6%;
    }

}