.serviceInfoContainer {
   padding: 3%;
}
.serviceInfoContainer{
    display: flex;
    width: 100%;
    gap: 50px;
}
.serviceInfoText h2 {
    font-family: 'saira';
    color: #9C9C9C;
    font-size: 32px;
}
.serviceInfoText p {
    font-family: 'scada';
    margin-bottom: 72px;
    color: #707070;
    font-size: 24px;
}

.serviceInfoText{
    width: 50%;
}

.projectImagesDiv{
    width: 50%;
}
#depoimentoInfoText{
    display: none;
}
.serviceInfoCard {
    display: flex;
    align-items: center;
    gap: 30px;
    padding-right: 20px;
}
.serviceInfoCard h2 {
    font-family: 'scada';
    font-weight: bold;
    font-size: 48px;
    color: #424242;
    margin-bottom: 0px;
}
.serviceInfoCard p {
    font-family: 'saira';
    font-weight: lighter;
    font-size: 24px;
    color: #707070;
}
.serviceInfoCard h3{
    font-family: 'scada';
    font-size: 1.1rem;
    color: #424242;
    
}
.serviceCardAvatar img{
    border-radius: 50%;
    width: 200px;    
}

/* Galeria */

.serviceInfoThumb {
    display: flex;
}

.serviceInfoThumb img{
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.7), 0px 20px 30px rgba(0, 0, 0, 0.3), 0px 10px 50px rgba(0, 0, 0, 0.2);
    background: #FFFFFF;
    border-radius: 5px;
}

.serviceInfoImagesOptions{
    display: flex;
    justify-content: space-around;
    margin-bottom: 8%;
}

.serviceInfoImagesOptions img{
    border-radius: 30px;

}
.serviceInfoImagesContainer {
    position: relative;
}

.projectImagesMobile{
    display: none;
}

/* Responsividade */

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */

@media screen and (min-width: 320px) and (max-width: 768px) {
    .projectImagesDesktop{
        display: none;
    }
    
    .projectImagesMobile{
        display: flex;
    }
    .serviceInfoContainer{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 50px;
        padding: 0%;
        margin-bottom: -130px;
    }

    .serviceInfoText{
        width: 100%;
        padding: 5%;
    }
    .projectImagesDiv{
        width: 100%;
    }

    .serviceInfoText h2 {
        font-family: 'saira';
        color: #9C9C9C;
        font-size: 28px;
    }
    .serviceInfoText p {
        font-family: 'scada';
        margin-bottom: 42px;
        padding-right: 20px;
        color: #707070;
        font-size: 20px;
        

    }
    #serviceButtonPurple2 {
        font-size: 15px;
        padding: 10px 20px 10px 20px;
    }
    #depoimentoInfoText{
        display: block;
    }
    .serviceInfoCard {
        display: flex;
        align-items: start;
        gap: 10px;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0px 2px 6px 0px #00000040;
        margin: 5%;
    }
    .serviceInfoCard h2 {
        font-family: 'scada';
        font-weight: bold;
        font-size: 28px;
        color: #424242;
        margin-bottom: 0px;
        padding-top: 12px;
        width: 0px;
    }
    .serviceInfoCard p {
        font-family: 'saira';
        font-weight: lighter;
        font-size: 16px;
        color: #707070;
        margin-top: 30px;
        margin-left: -88px ;
    }
    .serviceInfoCard h3{
        font-family: 'scada';
        font-size: 1.1rem;
        color: #424242;
        
    }
    .serviceCardAvatar img{
        border-radius: 50%;
        width: 78px;    
        
    }

    .serviceInfoImagesContainer {
        width: 100%;
    }

    .serviceInfoThumb{
        width: 100%;
    }

    .serviceInfoThumb img{
        width: 100%;
        box-shadow: none;
    }

    .serviceInfoImagesOptions{
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;
        margin-bottom: 20px;
        width: 100%;
        
    }

    .serviceInfoImages{
        display: flex;
        justify-content: center;
    }

    .serviceInfoImagesOptions img{
        border-radius: 8px;
    }
}

@media screen and (min-width: 320px) and (max-width:380px) {

    .serviceInfoThumb img{
        height: 200px;
    }

    .serviceInfoImagesOptions img{
        width: 150px;
        height: 100px;
    }
}



@media screen and (min-width: 381px) and (max-width:400px) {

    .serviceInfoThumb img{
        height: 220px;
    }

    .serviceInfoImagesOptions img{
        width: 170px;
        height: 120px;
    }
}

@media screen and (min-width: 401px) and (max-width:450px) {

    .serviceInfoThumb img{
        height: 240px;
    }

    .serviceInfoImagesOptions img{
        width: 185px;
        height: 120px;
        margin-left: 10px;
    }
}

@media screen and (min-width: 451px) and (max-width:480px) {

    .serviceInfoThumb img{
        height: 260px;
    }

    .serviceInfoImagesOptions img{
        width: 200px;
        height: 135px;
        margin-left: 10px;
    }
}


@media screen and (min-width: 481px) and (max-width: 640px) {

    .serviceInfoThumb img{
        height: 320px;
    }

    .serviceInfoImagesOptions img{
        width: 220px;
        height: 155px;
        margin-left: 10px;
    }
}


@media screen and (min-width: 641px) and (max-width: 720px) {

    .serviceInfoThumb img{
        height: 350px;
    }

    .serviceInfoImagesOptions img{
        width: 240px;
        height: 175px;
        margin-left: 10px;
    }
}

@media screen and (min-width: 721px) and (max-width: 768px) {

    .serviceInfoThumb img{
        height: 380px;
    }

    .serviceInfoImagesOptions img{
        width: 250px;
        height: 175px;
        margin-left: 10px;
    }
}


@media screen and (min-width: 769px) and (max-width: 999px) {
    .serviceCardAvatar img {
        border-radius: 50%;
        width: 150px;
    }
    
    .serviceInfoCard {
        display: flex;
        gap: 30px;
        flex-direction: column;
        text-align: center;
        padding-right: 0px;
      }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
    .serviceCardAvatar img {
        border-radius: 50%;
        width: 150px;
    }
    
    /* .serviceInfoCard p{
        font-size: 20px;
    } */

    .serviceInfoCard {
        display: flex;
        gap: 30px;
        text-align: left;
        padding-right: 0px;
      }
}

@media screen and (min-width: 769px) and (max-width: 1499px) {
    .serviceInfoThumb img{
        width: 100%;
    }

    .serviceInfoImagesOptions img{
        border-radius: 8px;
        width: 190px;
        height: 120px;
    }

    .serviceInfoImagesOptions{
        margin-top: 20px;
    }
}

@media screen and (min-width: 1500px){

    .serviceInfoThumb img{
        width: 100%;
    }

    .serviceInfoImagesOptions img{
        border-radius: 8px;
        width: 280px;
        height: 180px;
    }
    
    .serviceInfoImagesOptions{
        margin-top: 20px;
    }

    .serviceCardAvatar{
        margin-top: 30px;
    }
}