.valueCard {
    position: relative;
    width: 280px;
    background-color: white;
    margin: 0 32px;
    padding: 0 16px;
    transition: transform 0.5s;
}

.valueCard::before {
    content: "";
    position: absolute;
    inset: -20px 50px;
    border-top: 4px solid #8700A9;
    border-bottom: 4px solid #8700A9;
    transition: 0.5s ease-in-out;
}

.valueCard:hover::before {
    inset: -10px 50px;
}

.valueCard img {
    margin-top: 16px;
}

.valueCard h3 {
    font-family: "Scada";
    font-weight: bold;
    font-size: 32px;
    color: #610C7D;
}

.valueCard p {
    font-family: "Saira";
    font-weight: lighter;
    font-size: 16px;
    color: #9C9C9C;
}

.valueCard:hover {
    transform: scale(1.1);
    background-color: #8700A9;
}

.valueCard:hover h3, .valueCard:hover p {
    color: white;
}

.cardMobile {
    display: none;
}

/* Itens do Carrossel */

.valueItem { 
    display: none;
    flex-direction: row;
    width: 90%;
    height: 520px;
    margin: 0 0 96px 5%;
    align-items: center;
}

.valueItem img {
    position: relative;
    width: 440px;
}

.valueItem h3 {
    width: fit-content;
    font-size: 40px;
    margin-top: 16px;
    border-bottom: 7px solid #610C7D;
    text-align: left;
    color: #610C7D;
}

.valueItem p {
    text-align: left;
    font-size: 24px;
}

/* Responsividade */

/** Mobile**/
@media screen and (min-width: 320px) and (max-width: 480px) {

    .valueCard {
        display: none;
    }

    .valueItem { 
        display: none;
    }

    .cardMobile {
        display: flex;
    }

}

@media screen and (min-width: 481px) and (max-width: 768px) {

    .valueItem {
        display: flex;
        height: 420px;
    }

    .valueItem img {
        width: 280px;
    }
    
    .valueItem h3 {
        font-size: 32px;
    }
    
    .valueItem p {
        font-size: 20px;
    }

    .valueCard {
        display: none;
    }

}


/** Laptops **/
@media screen and (min-width: 769px) and (max-width: 1366px) {
    
    .valueItem {
        display: flex;
    }

    .valueCard {
        display: none;
    }

}

/** Desktop: Extra Large Screen **/
@media screen and (min-width: 1921px) {

}